<template>
  <v-app-bar color="primary" id="toolbar" clipped-left fixed dark app>
    <v-app-bar-nav-icon
      @click.stop="$store.dispatch('setDrawer', !$store.getters.drawer)"
    ></v-app-bar-nav-icon>

    <v-toolbar-title class="hidden-xs-only mr-5">
      <router-link to="/" v-if="logo">
        <img
          class="logo"
          :src="serverUrl + '/images/' + logo + '?token=' + userToken"
        />
      </router-link>
      <router-link to="/" v-else>
        <img contain class="logo" :src="getLogo" @error="failedImage = true" />
      </router-link>
    </v-toolbar-title>

    <div class="d-flex align-center" style="margin-left: auto">
      <languageSelector :whiteColors="true" />

      <v-btn
        outlined
        fab
        small
        class="mr-10"
        @click.stop="$store.dispatch('setSearchDrawer', true)"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn @click="$emit('logOut')" :loading="loggingOut" outlined>{{
        $t("Logout")
      }}</v-btn>
    </div>
  </v-app-bar>
</template>

<script>
import languageSelector from "../../shared/LanguageSelector";

export default {
  props: {
    loggingOut: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      logo: this.$config.logoNeg,
      userToken: this.$auth.userToken(),
      serverUrl: this.$helpers.getServerUrl(),
      failedImage: false,
    };
  },
  computed: {
    getLogo: {
      get() {
        return this.failedImage
          ? this.serverUrl +
              "/images/" +
              "logo-neg.png" +
              "?token=" +
              this.userToken +
              "&v=" +
              this.getDate()
          : this.serverUrl +
              "/images/" +
              "logo-neg.svg" +
              "?token=" +
              this.userToken +
              "&v=" +
              this.getDate();
      },
    },
  },

  methods: {
    getDate() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();

      return mm + dd + yyyy;
    },
  },
  components: {
    languageSelector,
  },
};
</script>

<style scoped>
.logo {
  height: 50px;
  margin-top: 5px;
}
</style>